import storage from "../../storage"
import { AuthTeacher, AuthTeacherImpl } from "../auth"

export interface State {
	teacher: AuthTeacher
	accessToken?: string
	refreshToken?: string
}

export class StateImpl implements State {
	teacher = storage.getTeacher() || new AuthTeacherImpl()
	accessToken = storage.getAccessToken()
	refreshToken = storage.getRefreshToken()
}