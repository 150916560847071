import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { Authed, AuthTeacherImpl } from '../types/auth'
import { State, StateImpl } from '../types/common/state'
import storage from '../storage'
import { InjectionKey } from 'vue/dist/vue.js'
import { Settings } from 'luxon'
import { Teacher } from '../types/teacher'
import { cloneFrom } from '../utils/clone'
import * as Sentry from '@sentry/browser'

export enum Mutations {
	AUTH = "auth",
	LOGOUT = "logout",
	AUTH_TEACHER = "authTeacher",
	COMPLETE_TEACHER_BASIC_INFO = "completeTeacherBasicInfo",
	COMPLETE_TEACHER_ACADEMIC_INFO = "completeTeacherAcademicInfo",
	COMPLETE_TEACHER_INFO = "completeTeacherInfo",
	CHANGE_TEACHER_AVATAR = "changeTeacherAvatar",
	CHANGE_TEACHER_ZONE_ID = "changeTeacherZoneId",
}

export enum Actions {
	AUTH = "auth",
	LOGOUT = "logout",
	COMPLETE_TEACHER_BASIC_INFO = "completeTeacherBasicInfo",
	COMPLETE_TEACHER_ACADEMIC_INFO = "completeTeacherAcademicInfo",
	COMPLETE_TEACHER_INFO = "completeTeacherInfo",
	CHANGE_TEACHER_AVATAR = "changeTeacherAvatar",
	CHANGE_TEACHER_ZONE_ID = "changeTeacherZoneId",
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore({
	strict: true,
	state: new StateImpl(),
	getters: {
		id: (state: State): string => {
			return state.teacher.id!
		},
		isAuth: (state: State): boolean => {
				return state.accessToken !== undefined
		},
		isComplete: (state: State): boolean => {
			return state.teacher.complete!
		},
		zoneId: (state: State): string => {
			return state.teacher.zoneId!
		}
	},
	// 同步动作
	mutations: {
		// 登录
		[Mutations.AUTH] (state: State, authed: Authed) {
			cloneFrom(state.teacher, authed.teacher)
			storage.setTeacher(state.teacher)

			state.accessToken = authed.access_token
			storage.setAccessToken(state.accessToken)

			state.refreshToken = authed.refresh_token
			storage.setRefreshToken(state.refreshToken)

			Sentry.setUser({
				id: state.teacher.id
		})
		},

		// 退出
		[Mutations.LOGOUT] (state: State) {
			// 清空state
			state.teacher = new AuthTeacherImpl()
			state.accessToken = undefined
			state.refreshToken = undefined

			// 清空storage
			storage.clean()

			Sentry.configureScope(scope => scope.setUser(null))
		},

		// 完善老师基础信息
		[Mutations.COMPLETE_TEACHER_BASIC_INFO] (state: State, teacher: Teacher) {
			state.teacher.avatar = teacher.avatar
			state.teacher.basicInfoComplete = teacher.basicInfoComplete
			storage.setTeacher(state.teacher)
		},

		// 完善老师教学信息
		[Mutations.COMPLETE_TEACHER_ACADEMIC_INFO] (state: State, teacher: Teacher) {
			state.teacher.academicInfoComplete = teacher.academicInfoComplete
			storage.setTeacher(state.teacher)
		},

		// 完善老师信息
		[Mutations.COMPLETE_TEACHER_INFO] (state: State, teacher: Teacher) {
			state.teacher.complete = teacher.complete
			storage.setTeacher(state.teacher)
		},

		// 更改老师头像
		[Mutations.CHANGE_TEACHER_AVATAR] (state: State, avatar: string) {
			state.teacher.avatar = avatar
			storage.setTeacher(state.teacher)
		},

		// 更改老师时区
		[Mutations.CHANGE_TEACHER_ZONE_ID] (state: State, zoneId: string) {
			state.teacher.zoneId = zoneId
			Settings.defaultZone = zoneId
			storage.setTeacher(state.teacher)
		},

	},
	// 异步动作
	actions: {
		async [Actions.AUTH] ({ commit }, authed: Authed) {
			commit(Mutations.AUTH, authed)
		},
		async [Actions.LOGOUT] ({ commit }) {
			commit(Mutations.LOGOUT)
		},
		async [Actions.COMPLETE_TEACHER_BASIC_INFO] ({ commit }, teacher: Teacher) {
			commit(Mutations.COMPLETE_TEACHER_BASIC_INFO, teacher)
		},
		async [Actions.COMPLETE_TEACHER_ACADEMIC_INFO] ({ commit }, teacher: Teacher) {
			commit(Mutations.COMPLETE_TEACHER_ACADEMIC_INFO, teacher)
		},
		async [Actions.COMPLETE_TEACHER_INFO] ({ commit }, teacher: Teacher) {
			commit(Mutations.COMPLETE_TEACHER_INFO, teacher)
		},
		async [Actions.CHANGE_TEACHER_AVATAR] ({ commit }, avatar: string) {
			commit(Mutations.CHANGE_TEACHER_AVATAR, avatar)
		},
		async [Actions.CHANGE_TEACHER_ZONE_ID] ({ commit }, zoneId: string) {
			commit(Mutations.CHANGE_TEACHER_ZONE_ID, zoneId)
		}
	}
})

export function useStore () {
	return baseUseStore(key)
}
