import axios, { AxiosRequestConfig, AxiosInstance } from "axios"

const config: AxiosRequestConfig = {
    baseURL: import.meta.env.VITE_AUTH_URL as string,
    timeout: 10000,
    responseType: 'json',
    auth: {
        username: 'api-service',
        password: 'secret'
    },
    params: {
        realm: 'teacher'
    }
}

const service: AxiosInstance = axios.create(config)

export default service