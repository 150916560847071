import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { inject } from '@vercel/analytics'
import { createHead } from "@vueuse/head"
import { createApp } from 'vue'
import config from '../package.json'
import App from './App.vue'
import router from './router'
import { key, store } from './store'

inject()

const app = createApp(App)

const environment = import.meta.env.VITE_ENVIRONMENT as string
if (environment !== 'dev') {
  switch(import.meta.env.VITE_APP_BRAND) {
    case 'CetuSchool': {
      Sentry.init({
        app,
        dsn: 'https://fb21a45b54b145d08c1b32a891049670@o1110879.ingest.sentry.io/6171024',
        // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        release: 'web-teacher@' + config.version,
        environment: environment,
        integrations: [
          new Sentry.Replay(),
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['teacher.staging.cetuschool.com', 'teacher.cetuschool.com', /^\//],
          })
        ]
      })
      break
    }
    case 'DDTalk': {
      Sentry.init({
        app,
        dsn: 'https://8bdfa81e203d4d15a63b38ef1d6f3adc@o4504817848549376.ingest.sentry.io/4504829166223360',
        // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        release: 'web-teacher@' + config.version,
        environment: environment,
        integrations: [
          new Sentry.Replay(),
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['teacher.staging.ddtalk.me', 'teacher.ddtalk.me', /^\//],
          })
        ]
      })
      break
    }
  }
}

let icon = ''
let theme = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    icon = '/icon/cetuschool.ico'
    theme = '/css/theme/cetuschool.min.css'
    break
  }
  case 'DDTalk': {
    icon = '/icon/ddtalk.ico'
    theme = '/css/theme/ddtalk.min.css'
    break
  }
}
const head = createHead({
  title: import.meta.env.VITE_APP_TITLE,
  link: [
    {
      rel: 'icon',
      href: icon
    },
    {
      rel: 'stylesheet',
      type: 'text/css',
      href: theme
    }
  ]
})

app.use(head)
  .use(router)
  .use(store, key)
  .mount('#app')