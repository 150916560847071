import storage from "../storage"
import { store } from "../store"

export enum AuthType {
	MOBILE_OTP = 'MOBILE_OTP',
	EMAIL_OTP = 'EMAIL_OTP',
	EMAIL_PASSWORD = 'EMAIL_PASSWORD'
}

export interface Auth {
	grant_type: string
	zoneId: string
}

export class AuthImpl implements Auth {
	grant_type: string = 'password'
	zoneId: string = store.state.teacher.zoneId
}

export interface EmailOtpAuth extends Auth {
	email: string
	otp: string
}

export class EmailOtpAuthImpl extends AuthImpl implements EmailOtpAuth {
	email: string = ''
	otp: string = ''
}

export interface EmailPasswordAuth extends Auth {
	email: string
	password: string
}

export class EmailPasswordAuthImpl extends AuthImpl implements EmailPasswordAuth {
	email: string = ''
	password: string = ''
}

export interface MobileOtpAuth extends Auth {
	countryCode: string
	mobile: string
	otp: string
}

export class MobileOtpAuthImpl extends AuthImpl implements MobileOtpAuth {
	countryCode: string = '001'
	mobile: string = ''
	otp: string = ''
}

export interface AuthTeacher {
	id?: string
	avatar?: string
	nickname?: string
  zoneId: string
	basicInfoComplete?: boolean
	academicInfoComplete?: boolean
  complete?: boolean
	vendor?: boolean
	locked?: boolean
}

export class AuthTeacherImpl implements AuthTeacher {
	zoneId = storage.getTeacher()?.zoneId || Intl.DateTimeFormat().resolvedOptions().timeZone
}

export interface Authed {
	access_token: string
	refresh_token: string
	teacher: AuthTeacher
}

export interface RefreshToken {
	grant_type: string
	refresh_token: string
}

export class RefreshTokenImpl implements RefreshToken {
	grant_type: string = 'refresh_token'
	refresh_token: string

	constructor(refreshToken: string) {
		this.refresh_token = refreshToken
	}
}